import Vue from 'vue'
import App from './App.vue'
import router from "./routes/index"
import firebase from "firebase/app"
import VueFirestore from 'vue-firestore'
import VCalendar from 'v-calendar'

Vue.config.productionTip = false

Vue.use(VueFirestore)
Vue.use(VCalendar)

var configOptions;
if (process.env.NODE_ENV == 'development') {
  configOptions = {
    apiKey: "AIzaSyAl7hi7WzPl-7UABlnUhsx-AL30BkryGh4",
    authDomain: "floreer-checkout-dev.firebaseapp.com",
    databaseURL: "https://floreer-checkout-dev.firebaseio.com",
    projectId: "floreer-checkout-dev",
    storageBucket: "floreer-checkout-dev.appspot.com",
    messagingSenderId: "917909485709",
    appId: "1:917909485709:web:5d197d1aba915f92c24ac4"
  };
} else {
  configOptions = {
    apiKey: "AIzaSyAx3IbaLwuqbqB3Vb3KwzehftZD_veQw2E",
    authDomain: "floreer-checkout.firebaseapp.com",
    databaseURL: "https://floreer-checkout.firebaseio.com",
    projectId: "floreer-checkout",
    storageBucket: "floreer-checkout.appspot.com",
    messagingSenderId: "571104082007",
    appId: "1:571104082007:web:8e95c70683494c0d778b12"
  };
}

firebase.initializeApp(configOptions)

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
