<template>
    <button v-on:click="onClick()" v-bind:disabled="flower.amount == 0">
        <img :src="flower.image">
        <span>{{ flower.name }}<br>{{ flower.amount }} aan {{ flower.cost }}</span>
    </button>
</template>

<script>
export default {
    props: ['flower'],
    methods: {
        onClick: function(){
            this.$emit('click');
        }
    }
};
</script>

<style scoped>
button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin: 10px;
    max-width: 170px;
}
button img {
    width: 150px;
    height: 150px;
}
button:disabled img {
    opacity: 0.3;
    filter: grayscale(100%);
}
button span {
    padding-top: 5px;
}
</style>