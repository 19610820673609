import Vue from 'vue';
import Router from 'vue-router';
import Index from '../components/Index';
import Login from '../components/Login';
import Bouquet from '../components/Bouquet';
import Stock from '../components/Stock';
import Invoices from '../components/Invoices';
import firebase from "firebase/app"

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'index',
            component: Index,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/bouquet',
            name: 'bouquet',
            component: Bouquet,
            props: {
                fallbackImage: "stock-fallback.svg",
                margin: 2.4,
                stockCollection: "stock",
                stockLife: 7,
                stockRoute: 'stock',
                vatrate: 0.06,
                title: "Vers boeket",
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/drybouquet',
            name: 'drybouquet',
            component: Bouquet,
            props: {
                fallbackImage: "drystock-fallback.svg",
                margin: 3.5,
                stockCollection: "drystock",
                stockLife: -1,
                stockRoute: "drystock",
                vatrate: 0.21,
                title: "Droogboeket",
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/stock',
            name: 'stock',
            component: Stock,
            props: {
                type: 'freshstock',
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/drystock',
            name: 'drystock',
            component: Stock,
            props: {
                type: 'drystock',
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/invoices',
            name: 'invoices',
            component: Invoices,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '*', 
            redirect:  { name: 'index' },
        },
    ]
});

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.app().auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

router.beforeEach(async (to, _, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !await firebase.getCurrentUser()){
      next('login');
    }else{
      next();
    }
});

export default router