<template>
    <div class="page">
        <!-- /start flower grid -->
        <div v-if="flowerLifestock.length" class="flower-grid">
            <flower-button
                v-for="flower in flowerLifestock"
                :flower="flower"
                :key="flower.id"
                v-on:click="add(flower)"
            ></flower-button>
        </div>
        <div v-else class="flower-grid-empty">
            <span>
                Oeps, er zijn nog geen bloemen toegevoegd aan de stock.<br>
                <router-link :to="stockRoute">Bloemen toevoegen</router-link>
            </span>
        </div>
        <!-- /end flower grid -->
        <div class="bouquet" v-bind:class="{ 'bouquet--empty': Object.keys(bouquet.flowers) }">
            <h2>Bloemenrecept<span style="float: right">€ {{ (bouquet.price * (1 + bouquet.vatrate)).toFixed(2)}}</span></h2>
            <div class="bouquet__overview" v-if="bouquet.price > 0">
                <div>
                    <table style="width: 100%">
                        <thead>
                            <tr>
                                <td style="text-align: right;">Aantal</td>
                                <td>Type</td>
                                <td style="text-align: right;">Prijs</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(flower, key) in bouquet.flowers" :key="key">
                                <td style="display: flex; justify-content: space-between; align-items: center">
                                    <button v-on:click="remove(flower, key)" title="Verwijderen uit boeket" style="border: none; background: none; font-weight: bold">&ndash;</button>
                                    <span>{{ flower.amount }}</span>
                                </td>
                                <td>{{ flower.name }}</td>
                                <td style="text-align: right;">{{ flower.price.toFixed(2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="bouquet__summary">
                        <span>Subtotaal:</span><span class="bouquet__price">{{ bouquet.price.toFixed(2) }}</span>
                        <span>BTW:</span><span class="bouquet__price">{{ (bouquet.price * bouquet.vatrate).toFixed(2) }}</span>
                        <span>Totaal:</span><span class="bouquet__price">{{ (bouquet.price * (1 + bouquet.vatrate)).toFixed(2)}}</span>
                    </div>
                    <div class="bouquet__remark">
                        <span>Opmerking:</span>
                        <textarea v-model="bouquet.comment"></textarea>
                    </div>
                </div>
                <div class="bouquet__payment">
                    <button alt="cart" title="In de winkelmand plaatsen" disabled style="font-size: 35px; justify-content: center;">🛒</button>
                    <button alt="cash" title="Cash betalen" v-on:click="$refs.modal.open()">
                        <svg class="bouquet__payment__icon" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    </button>
                    <button alt="bancontact" title="Betalen met bancontact" disabled>
                        <svg class="bouquet__payment__icon"  viewBox="0 0 326.1 230.5">
                            <g>
                                <path fill="#FFFFFF" d="M326.1,210.7c0,10.9-8.9,19.8-19.8,19.8H19.9c-10.9,0-19.8-8.9-19.8-19.8V19.8C0.1,8.9,9,0,19.9,0h286.4
                                    c10.9,0,19.8,8.9,19.8,19.8L326.1,210.7L326.1,210.7z"/>
                            </g>
                            <g>
                                <path fill="#005498" d="M262,64.4h-11.8h-54.6h-11.9l-8,9l-25.8,29.1l0,0l-8,9h-11.8H76.5H64.7l7.8-9.1l3.7-4.3L84,89H72.2H57h-8.5
                                    c-6.5,0-11.8,5.5-11.8,12.1l0,0v23.4v2.2c0,6.7,5.3,12.1,11.8,12.1h3.3h124h8.9c6.5,0,15.4-4,19.7-9l20.6-23.4L262,64.4z"/>
                                <path fill="#FFD800" d="M277.7,37.1c6.5,0,11.8,5.5,11.8,12.1v25.5c0,6.7-5.3,12.1-11.8,12.1h-6.4h-17.2h-11.8l7.9-9l0,0l3.9-4.5
                                    l7.9-9h-78.3l-41.7,47H64.3l55.7-63l2.1-2.4c4.4-4.9,13.3-9,19.8-9h2.7h133.1V37.1z"/>
                            </g>
                            <path fill="#005498" d="M289.5,192.4v-3.7c0-0.5-0.3-0.8-1-0.8h-2.4c-0.7,0-1.2-0.1-1.3-0.4c-0.2-0.3-0.2-0.9-0.2-1.9v-14h3.9
                                c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7v-3.9c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-3.9v-5.1c0-0.3-0.1-0.5-0.2-0.6
                                c-0.2-0.1-0.4-0.2-0.6-0.2h-0.1l-5.8,1c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.6v3.9H273c-0.3,0-0.5,0.1-0.7,0.3
                                s-0.3,0.4-0.3,0.7v3.2c0,0.3,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.7,0.4l3.9,0.6v14c0,1.7,0.2,3.1,0.5,4.2c0.4,1.1,0.9,1.9,1.5,2.5
                                c0.7,0.6,1.5,1,2.5,1.2s2.2,0.3,3.5,0.3c0.7,0,1.3,0,1.9-0.1c0.5-0.1,1.2-0.2,1.9-0.3C289.2,193.2,289.5,192.9,289.5,192.4
                                M268.7,191.9v-4.3c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.6-0.2h-0.1c-0.9,0.1-1.8,0.2-2.6,0.2c-0.8,0.1-1.9,0.1-3.3,0.1
                                c-0.5,0-1.1-0.1-1.5-0.3c-0.5-0.2-0.9-0.5-1.3-0.9s-0.6-1-0.8-1.7c-0.2-0.7-0.3-1.6-0.3-2.7v-4c0-1.1,0.1-2,0.3-2.7
                                c0.2-0.7,0.5-1.3,0.8-1.7c0.4-0.4,0.8-0.7,1.3-0.9s1-0.3,1.5-0.3c1.4,0,2.5,0,3.3,0.1s1.7,0.1,2.6,0.2h0.1c0.3,0,0.5-0.1,0.6-0.2
                                c0.2-0.1,0.3-0.3,0.3-0.6v-4.3c0-0.4-0.1-0.6-0.2-0.7c-0.2-0.1-0.4-0.3-0.8-0.4c-0.7-0.2-1.5-0.3-2.6-0.5s-2.3-0.2-3.8-0.2
                                c-3.4,0-6.1,1-8.2,3.1c-2,2.1-3.1,5.1-3.1,9.1v4c0,3.9,1,7,3.1,9.1c2,2.1,4.8,3.1,8.2,3.1c1.4,0,2.7-0.1,3.8-0.2
                                c1.1-0.2,2-0.3,2.6-0.5c0.4-0.1,0.6-0.2,0.8-0.4C268.7,192.5,268.7,192.2,268.7,191.9 M237.4,186.8c-0.6,0.3-1.2,0.5-1.9,0.7
                                c-0.7,0.2-1.4,0.3-2.1,0.3c-1,0-1.8-0.1-2.3-0.4s-0.7-0.9-0.7-2V185c0-0.6,0.1-1.1,0.2-1.5s0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.5,1.3-0.6
                                s1.2-0.2,2.1-0.2h2.7L237.4,186.8L237.4,186.8z M245,175.1c0-1.8-0.3-3.3-0.8-4.5s-1.3-2.2-2.2-3c-1-0.8-2.1-1.4-3.5-1.7
                                c-1.4-0.4-3-0.6-4.7-0.6c-1.6,0-3.2,0.1-4.7,0.3s-2.7,0.4-3.6,0.7c-0.6,0.2-0.9,0.5-0.9,1.1v3.9c0,0.3,0.1,0.5,0.2,0.7
                                c0.2,0.1,0.4,0.2,0.6,0.2h0.2c0.4,0,0.9-0.1,1.4-0.1c0.6,0,1.2-0.1,1.9-0.1c0.7,0,1.5-0.1,2.3-0.1s1.6,0,2.3,0c1.1,0,2,0.2,2.6,0.6
                                c0.6,0.4,1,1.3,1,2.7v1.7h-2.6c-4.1,0-7.2,0.6-9,1.9c-1.9,1.3-2.8,3.4-2.8,6.2v0.4c0,1.6,0.2,2.9,0.7,3.9c0.5,1.1,1.1,1.9,1.9,2.6
                                c0.8,0.6,1.6,1.1,2.6,1.4s2,0.4,3.1,0.4c1.4,0,2.7-0.2,3.7-0.6s2-0.9,3-1.6v0.8c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h5.4
                                c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-17.2H245z M219.3,192.4v-3.7c0-0.5-0.3-0.8-1-0.8h-2.4c-0.7,0-1.2-0.1-1.3-0.4
                                c-0.2-0.3-0.2-0.9-0.2-1.9v-14h3.9c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7v-3.9c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-3.9v-5.1
                                c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.2h-0.1l-5.8,1c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.6v3.9h-4
                                c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v3.2c0,0.3,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.7,0.4l3.9,0.6v14c0,1.7,0.2,3.1,0.5,4.2
                                c0.4,1.1,0.9,1.9,1.5,2.5c0.7,0.6,1.5,1,2.5,1.2s2.2,0.3,3.5,0.3c0.7,0,1.3,0,1.9-0.1c0.5-0.1,1.2-0.2,1.9-0.3
                                C219,193.2,219.3,192.9,219.3,192.4 M198.3,192.3v-16c0-1.5-0.1-2.9-0.4-4.3c-0.2-1.3-0.7-2.5-1.3-3.5c-0.6-1-1.5-1.8-2.6-2.3
                                c-1.1-0.6-2.5-0.9-4.3-0.9c-1.5,0-2.9,0.2-4.1,0.6c-1.2,0.4-2.4,1-3.8,2v-1.2c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-5.4
                                c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v25.6c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3h5.8c0.3,0,0.5-0.1,0.7-0.3
                                c0.2-0.2,0.3-0.4,0.3-0.7v-18.9c0.8-0.4,1.6-0.8,2.4-1.1c0.7-0.3,1.5-0.4,2.2-0.4s1.3,0.1,1.8,0.2c0.5,0.1,0.8,0.4,1.1,0.7
                                c0.3,0.4,0.4,0.8,0.5,1.4c0.1,0.6,0.1,1.3,0.1,2.1v16c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h5.8c0.3,0,0.5-0.1,0.7-0.3
                                C198.2,192.8,198.3,192.5,198.3,192.3 M161.9,181.2c0,3.9-1.4,5.9-4.3,5.9c-1.4,0-2.5-0.5-3.2-1.5s-1.1-2.5-1.1-4.4v-3.4
                                c0-2,0.4-3.4,1.1-4.4c0.7-1,1.8-1.5,3.2-1.5c2.8,0,4.3,2,4.3,5.9V181.2z M169.7,177.8c0-1.9-0.3-3.7-0.8-5.2s-1.3-2.8-2.3-3.9
                                c-1-1.1-2.3-1.9-3.8-2.5s-3.2-0.9-5.2-0.9s-3.7,0.3-5.2,0.9c-1.5,0.6-2.8,1.4-3.8,2.5s-1.8,2.4-2.3,3.9s-0.8,3.3-0.8,5.2v3.4
                                c0,1.9,0.3,3.7,0.8,5.2s1.3,2.8,2.3,3.9s2.3,1.9,3.8,2.5s3.2,0.9,5.2,0.9s3.7-0.3,5.2-0.9s2.8-1.4,3.8-2.5s1.8-2.4,2.3-3.9
                                s0.8-3.3,0.8-5.2V177.8z M143,191.9v-4.3c0-0.3-0.1-0.5-0.3-0.6s-0.4-0.2-0.7-0.2h-0.1c-0.9,0.1-1.8,0.2-2.6,0.2
                                c-0.8,0.1-1.9,0.1-3.3,0.1c-0.5,0-1.1-0.1-1.5-0.3c-0.5-0.2-0.9-0.5-1.3-0.9c-0.4-0.4-0.6-1-0.8-1.7s-0.3-1.6-0.3-2.7v-4
                                c0-1.1,0.1-2,0.3-2.7s0.5-1.3,0.8-1.7c0.4-0.4,0.8-0.7,1.3-0.9s1-0.3,1.5-0.3c1.4,0,2.5,0,3.3,0.1s1.7,0.1,2.6,0.2h0.1
                                c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6v-4.3c0-0.4-0.1-0.6-0.2-0.7c-0.2-0.1-0.4-0.3-0.8-0.4c-0.7-0.2-1.5-0.3-2.6-0.5
                                s-2.3-0.2-3.8-0.2c-3.4,0-6.1,1-8.2,3.1c-2,2.1-3.1,5.1-3.1,9.1v4c0,3.9,1,7,3.1,9.1c2,2.1,4.8,3.1,8.2,3.1c1.4,0,2.7-0.1,3.8-0.2
                                c1.1-0.2,1.9-0.3,2.6-0.5c0.4-0.1,0.6-0.2,0.8-0.4C142.9,192.5,143,192.2,143,191.9 M119.3,192.3v-16c0-1.5-0.1-2.9-0.4-4.3
                                c-0.2-1.3-0.7-2.5-1.3-3.5s-1.5-1.8-2.6-2.3c-1.1-0.6-2.5-0.9-4.3-0.9c-1.5,0-2.9,0.2-4.1,0.6c-1.2,0.4-2.4,1-3.8,2v-1.2
                                c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-5.4c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v25.6c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3
                                h5.8c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-18.9c0.8-0.4,1.6-0.8,2.4-1.1c0.7-0.3,1.5-0.4,2.2-0.4s1.3,0.1,1.8,0.2
                                c0.5,0.1,0.8,0.4,1.1,0.7c0.3,0.4,0.4,0.8,0.5,1.4c0.1,0.6,0.1,1.3,0.1,2.1v16c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3h5.8
                                c0.3,0,0.5-0.1,0.7-0.3C119.2,192.8,119.3,192.5,119.3,192.3 M82.1,186.8c-0.6,0.3-1.2,0.5-1.9,0.7s-1.4,0.3-2.1,0.3
                                c-1,0-1.8-0.1-2.3-0.4s-0.7-0.9-0.7-2V185c0-0.6,0.1-1.1,0.2-1.5c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.5,1.3-0.6s1.2-0.2,2.1-0.2
                                h2.7V186.8z M89.8,175.1c0-1.8-0.3-3.3-0.8-4.5s-1.3-2.2-2.2-3c-1-0.8-2.1-1.4-3.5-1.7c-1.4-0.4-3-0.6-4.7-0.6
                                c-1.6,0-3.2,0.1-4.7,0.3s-2.7,0.4-3.6,0.7c-0.6,0.2-0.9,0.5-0.9,1.1v3.9c0,0.3,0.1,0.5,0.2,0.7c0.2,0.1,0.4,0.2,0.6,0.2h0.2
                                c0.4,0,0.9-0.1,1.4-0.1c0.6,0,1.2-0.1,2-0.1c0.7,0,1.5-0.1,2.3-0.1s1.6,0,2.3,0c1.1,0,2,0.2,2.6,0.6c0.6,0.4,1,1.3,1,2.7v1.7h-2.6
                                c-4.1,0-7.2,0.6-9,1.9c-1.9,1.3-2.8,3.4-2.8,6.2v0.4c0,1.6,0.2,2.9,0.7,3.9c0.5,1.1,1.1,1.9,1.9,2.6c0.8,0.6,1.6,1.1,2.6,1.4
                                s2,0.4,3.1,0.4c1.4,0,2.7-0.2,3.7-0.6s2-0.9,3-1.6v0.8c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3H89c0.3,0,0.5-0.1,0.7-0.3
                                c0.2-0.2,0.3-0.4,0.3-0.7v-17.2H89.8z M55.4,182.7c0,1.3-0.5,2.4-1.4,3.1s-2.7,1.1-5.1,1.1h-0.8c-0.4,0-0.8,0-1.2,0
                                c-0.4,0-0.8,0-1.2,0h-0.8v-9h5.4c1.9,0,3.3,0.4,4,1.3c0.7,0.9,1.1,1.9,1.1,3V182.7z M55.2,168.4c0,0.5-0.1,1-0.2,1.5
                                c-0.2,0.5-0.4,0.9-0.8,1.2s-0.9,0.6-1.5,0.8s-1.4,0.3-2.4,0.3h-5.4v-8.3c0.2,0,0.4,0,0.7,0c0.3,0,0.7,0,1.1,0h1.1h0.8
                                c2.5,0,4.2,0.3,5.2,0.9c1,0.6,1.5,1.6,1.5,2.9L55.2,168.4L55.2,168.4z M63.4,182.2c0-1.7-0.4-3.1-1.2-4.3s-1.8-2.2-3.1-2.8
                                c1.3-0.6,2.3-1.6,3-2.8c0.7-1.3,1.1-2.7,1.1-4.2v-0.9c0-1.9-0.4-3.5-1.1-4.8c-0.7-1.3-1.8-2.3-3.1-3.1s-2.9-1.3-4.8-1.7
                                c-1.9-0.3-3.9-0.5-6.2-0.5c-0.8,0-1.6,0-2.4,0s-1.6,0.1-2.4,0.1s-1.5,0.1-2.2,0.2c-0.7,0.1-1.2,0.1-1.6,0.2c-0.9,0.2-1.6,0.4-2,0.9
                                c-0.4,0.4-0.6,1.2-0.6,2.3v29.3c0,1.1,0.2,1.8,0.6,2.3c0.4,0.4,1.1,0.7,2,0.9c0.5,0.1,1.1,0.2,1.7,0.2c0.7,0.1,1.4,0.1,2.2,0.2
                                s1.6,0.1,2.4,0.1s1.7,0,2.5,0c2.1,0,4.1-0.2,5.9-0.5c1.8-0.3,3.4-0.9,4.8-1.7c1.4-0.8,2.4-1.9,3.3-3.3c0.8-1.4,1.2-3.2,1.2-5.3
                                L63.4,182.2L63.4,182.2z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="bouquet__overview bouquet__overview--empty" v-else>Nog geen bloemen in dit recept.</div>
            <modal ref="modal" v-on:confirm="confirmCashPayment">
                <span slot="header">Betaling bevestigen</span>
                <span slot="body">
                    Is deze betaling voldaan?
                    <div class="bouquet__summary">
                        <span>Subtotaal:</span><span class="bouquet__price">{{ bouquet.price.toFixed(2) }}</span>
                        <span>BTW:</span><span class="bouquet__price">{{ (bouquet.price * bouquet.vatrate).toFixed(2) }}</span>
                        <span>Totaal:</span><span class="bouquet__price">{{ (bouquet.price * (1 + bouquet.vatrate)).toFixed(2) }}</span>
                    </div>
                </span>
            </modal>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app";
import FlowerButton from './FlowerButton.vue';
import Modal from './Modal.vue';

const ONE_DAY = 24 * 60 * 60 * 1000;

export default {
    components: {
        FlowerButton,
        Modal,
    },
    props: [ 'margin', 'fallbackImage', 'stockLife', 'stockCollection', 'stockRoute', 'title', 'vatrate'],
    data () {
        return {
            flowerLifestock: [ ],
            bouquet: {
                price: 0,
                vatrate: this.vatrate,
                flowers: {},
            },
        }
    },
    methods: {
        add: function(flower) {
            let f = this.bouquet.flowers[flower.type]
            if (!f) {
                f = {
                    amount: 0,
                    name: flower.name,
                    price: 0,
                    raw: [],
                };
            }
            f.amount++;
            f.price += flower.cost;
            f.raw.push(flower.raw.shift());
            this.bouquet.flowers[flower.type] = f;
            this.bouquet.price += flower.cost;
            flower.amount--;
        },
        remove: function(f, type) {
            // put the flower back in the available queue
            let flower = this.flowerLifestock.find(fl => { return fl.type == type })
            flower.raw.unshift(f.raw.pop());
            flower.amount++;

            f.price -= flower.cost;
            f.amount--;

            if (f.amount == 0) {
                delete(this.bouquet.flowers[type]);
            }
            this.bouquet.price -= flower.cost;
        },
        confirmCashPayment: function() {
            this.bouquet.date = new Date();
            this.bouquet.payment_method = "cash";

            let invoiceRef = this.$firestore.invoices.doc();
            var batch = firebase.firestore().batch()

            // Mark the flowers as sold
            // Can cause issues when used concurrently
            Object.entries(this.bouquet.flowers).forEach((entry) => {
                const f = entry[1];
                f.raw.forEach((flower) => {
                    batch.update(flower.ref, {
                        sold: invoiceRef.path
                    })
                })
                // Remove the raw firebase objects from the bouquet
                // They cause issues with the firebase request
                delete f.raw
            })

            // Add the invoice to the batch
            batch.set(invoiceRef, this.bouquet);
            batch.commit()
                .then(() => {
                    this.$router.replace({ name: "index" });
                }, e => {
                    this.error = e;
                })
                .catch(e => {
                    this.error = e;
                });
        },
    },
    firestore() {
        return {
            purchases: firebase.app().firestore().collection(this.stockCollection),
            invoices: firebase.app().firestore().collection('invoices'),
        }
    },
    mounted() {
        var query = this.$firestore.purchases.where('sold', '==', null)

        if (this.stockLife > -1) {
            var today = new Date();
            today.setHours(23);
            today.setMinutes(59);
            today.setSeconds(59);

            // Only request flowers that were delivered less than X days ago.
            var flowersSellableLife = this.stockLife * ONE_DAY;
            var expireDate = new Date(today.getTime() - flowersSellableLife);

            query = query.where('date', '>=', expireDate).where('date', '<=', today);
        }

        query.get().then(snapshot => {
            var stock = []
            snapshot.forEach(doc => {
                var e = doc.data()
                let added = false;
                for (let i = 0; i < stock.length; i++) {
                    if (stock[i].type == e.type && stock[i].price == e.price) {
                        stock[i].raw.push(doc);
                        stock[i].amount++;
                        added = true;
                    }
                }
                if (!added) {
                    stock.push({ 
                        name: e.type,
                        type: e.type,
                        date: e.date,
                        image: "./images/" + this.fallbackImage,
                        amount: 1, 
                        cost: Math.round((e.price * this.margin) * 100) / 100.,
                        price: e.price,
                        raw: [doc]
                    })
                }
            })
            stock.sort((a,b) => {
                return ('' + a.name).localeCompare(b.name);
            })
            this.flowerLifestock = stock
        }, e => {console.log(e)});
    },
}
</script>

<style scoped>
button:hover {
    cursor:  pointer; 
}
div.page {
    display: grid;
    grid-template-columns: 1fr minmax(25%, 250px);
    height: 100%;
}
@media only screen and (max-width: 700px) {
    div.page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}
.flower-grid {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
}
.flower-grid-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.bouquet {
    border-left: 1px solid var(--main-color-dark);
    box-shadow: -10px 0 12px -11px var(--main-color-dark);
    padding: 10px;
    background-color: white;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.bouquet h2 {
    color: var(--main-color-dark);
}
.bouquet__overview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.bouquet__overview tbody:before {
    line-height:5px; content:"\200C"; display:block;
}
.bouquet__summary {
    border-top: 1px solid var(--main-color);
    padding-top: 1rem;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    font-size: 1.1rem;
}
.bouquet__summary div table tbody tr {
    padding: 4px;
}
.bouquet__remark {
    padding-top: 2rem;
    margin-top: 2   rem;
    display: flex;
    flex-direction: column;
}
.bouquet__remark textarea {
    max-width: 100%;
    height: 4rem;
}
.bouquet__price {
    font-weight: bold;
    text-align: right;
}
.bouquet__price::before {
    content: "€";
    padding-right: 0.5rem;
}
.bouquet__payment {
    display: flex; 
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin-top: 20px;
}
.bouquet__payment * {
    width: 100%
}
.bouquet__payment button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
}
.bouquet__payment button:disabled svg {
    opacity: 0.3;
    filter: grayscale(100%);
}
.bouquet__payment__icon {
    width: 50px;
    height: 50px;
}
</style>
