<template>
    <nav v-if="user" v-bind:class="{ 'development': development }">
        <span class="logo">
            <router-link to="index">floreer</router-link>
            <span><img v-if="user.photoURL" referrerpolicy="no-referrer" :src="user.photoURL"></span>
            <span class="name">{{ user.displayName }}</span>
        </span>
        <span v-if="development"><strong>!!! DEVELOPMENT ENVIRONMENT !!!</strong></span>
        <span class="auth">
            <router-link to="cart">🛒 Winkelwagen</router-link>
            <span><a href="#" v-on:click="logout()">Log out</a></span>
        </span>
    </nav>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export default {
    data() {
        return {
            development: process.env.NODE_ENV == 'development',
            user: null,
        }
    },
    methods: {
        logout: function() {
            firebase.app().auth().signOut().then(() => {
                this.$router.replace({ name: "login" });
            })
        },
    },
    mounted() {
        var that = this;
        firebase.app().auth().onAuthStateChanged(function(user) {    
            that.user = user
        })
    },
}
</script>

<style scoped>
nav{
    padding: 5px 15px;
    color: #ffffff;
    background-color: var(--main-color);
    border-bottom: 1px solid var(--main-color-dark);
    display: flex;
    justify-content: space-between;
    align-items : center;
}
nav.development {
    background-color: lightskyblue;
}
span {
    margin: 0 5px;
}
@media only screen and (max-width: 700px) {
    nav{
        padding: 5px;
    }
    span {
        margin: 0 3px;
    }
}
.logo {
    display: flex;
    align-items: center;
    vertical-align: middle;
}
.logo a {
    text-decoration: none;
    font-size: 3.25em; 
    font-family: 'Dancing Script','Great Vibes', cursive;
    padding-right: 2rem;
}
.auth {
    display: flex;
    align-items: center;
    vertical-align: middle;
}
.name{
    font-weight: bold;
}
img {
    height: 2rem;
    padding: 2px;
    border: 1px solid var(--main-color-dark);
    border-radius: 50%;
}
a {
    color: #ffffff;
}
</style>