<template>
    <div class="page">
        <h1>Inkoop {{ settings[type].title }}</h1>
        <div v-if="error" class="error">
            {{ error }}
        </div>
        <form v-on:submit.prevent action="">
            <label for="vendor">Winkel</label>
            <input type="text" v-model="purchase.vendor" style="grid-column: span 2">
            <label for="vendor">Vaste kost</label>
            <input type="number" step="0.01" v-model="purchase.fixedCost" style="grid-column: span 2">
            <label for="vendor" v-if="settings[type].withDate">Leveringsdatum</label>
            <v-date-picker v-if="settings[type].withDate" mode="date" color="purple" v-model="purchase.date">
                <template v-slot="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" />
                </template>
            </v-date-picker>

            <div>
                <label for="store">Aantal</label>
                <label for="store">Naam</label>
                <label for="store">Prijs</label>
                <label style="width: 10rem"></label>
            </div>
            <div v-for="(flower, index) in purchase.flowers" v-bind:key="index">
                <input type="number" required v-model="flower.amount">
                <input type="text" v-model="flower.id">
                <input type="number" step="0.01" v-model="flower.price">
                <button v-on:click="remove(index)" style="width: 10rem">-</button>
            </div>

            <div>
                <button style="width: auto" v-on:click="store()">Opslaan</button>
                <button style="width: auto" v-on:click="add()">+ Bloem toevoegen</button>
            </div>
        </form>
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";

function round(x) {
    return Math.trunc(100 * x) / 100;
}

export default {
    props: [ 'type' ],
    data() {
        return {
            settings: {
                "freshstock": {
                    title: "Verse bloemen",
                    withDate: true,
                    collection: "stock",
                    redirect: "bouquet",
                },
                "drystock": {
                    title: "Droogbloemen",
                    withDate: false,
                    collection: "drystock",
                    redirect: "drybouquet",
                }
            },
            error: null,
            purchase: {
                vendor: "Bloomer",
                fixedCost: 0,
                date: new Date(),
                flowers: [
                    { amount: 1, id: "", name: "", price: 0.5 }
                ],
            },
        }
    },
    firestore() {
      return {
        stock: firebase.app().firestore().collection(this.settings[this.type].collection),
      }
    },
    methods: {
        'store': function() {
            this.purchase.flowers = this.purchase.flowers.filter((flower) => {
                return flower.id != "" && flower.stems != "";
            })

            var stems = 0;
            this.purchase.flowers.forEach((flower) => {
                stems += parseInt(flower.amount);
            })
            var fixedCostPerStem = this.purchase.fixedCost / stems;
            console.log("Fixed cost per stem: " + fixedCostPerStem);

            this.purchase.flowers.forEach((flower) => {
                var batch = firebase.firestore().batch();
                for (let i = 0; i < flower.amount; i++) {
                    batch.set(this.$firestore.stock.doc(), {
                        type: flower.id,
                        source: this.purchase.vendor,
                        price: round(fixedCostPerStem + parseFloat(flower.price)),
                        date: this.purchase.date,
                        sold: null,
                    });
                }
                batch.commit()
                    .then(() => {
                        console.log("stored flower " + flower.id);
                    }, e => {
                        this.error = e;
                    })
                    .catch(e => {
                        this.error = e;
                    });
            })
            if (!this.error) {
                this.$router.replace({ name: this.settings[this.type].redirect });
            }
        },
        'add': function() {
            this.purchase.flowers.push({ amount: 1, id: "", name: "", price: 0.5 })
            return true;
        },
        'remove': function(index) {
            this.purchase.flowers.splice(index, 1)
            return true;
        },
    }
}
</script>

<style scoped>
h1 {
    color: var(--main-color-dark);
}
.page {
    min-width: 50%;
    margin: 0 auto;
    padding: 10px;
}
form {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px 10px;
}
input, select {
    padding: 8px 12px;
    line-height: 1.25;
    border: 1px solid #cbd5e0;
    border-radius: 4px;
    background-color: #fff;
}
form div {
    text-align: left;
    grid-column: span 3;
    display: flex;
    justify-content: flex-start;
    grid-gap: 15px 10px;
}
form div * {
    width: 100%;
}
button {
    padding: 10px;
    color: #ffffff;
    background-color: var(--main-color-dark);
}
label {
    margin: auto 0;
}
</style>