<template>
    <transition name="modal" v-if="showModal">
        <div class="modal-mask" ref="mask" @keydown.esc="cancel" tabindex="-1">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <slot name="header">
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <button class="modal-default-button" @click="cancel">
                            Annuleren
                        </button>
                        <button class="modal-default-button" @click="confirm">
                            Bevestigen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default ({
    data() { 
        return {
            showModal: false,
        }
    },
    methods: {
        open: function() {
            this.showModal = true;
        },
        cancel: function() {
            this.showModal = false;
            this.$emit('cancel');
        },
        confirm: function() {
            this.showModal = false;
            this.$emit('confirm');
        }     
    },
    updated(){
        if (this.showModal) {
            this.$refs.mask.focus();
        }
    }
});
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  font-size: 1.1rem;
  min-width: 40%;
  max-width: 95%;
}

.modal-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid var(--main-color-dark);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-container > * {
  padding: 20px 20px;
}

.modal-header {
  color: #ffffff;
  font-weight: bold;
  background-color: var(--main-color);
  border-bottom: 1px solid var(--main-color-dark);
  border-radius: 2px 2px 0 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.modal-footer button{
    margin-left: 10px;
    padding: 10px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>