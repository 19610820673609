<template>
  <div id="app">
    <svg style="position: fixed; bottom: -80; left: -110; width: 600px; height: 600px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.625 47.625" height="180" width="180">
      <g transform="rotate(45, 22.5, 22.5) matrix(.26458 0 0 .26458 -139.627857 -74.68784) " stroke="#fff" fill="none">
        <path transform="matrix(9.17042 -6.21372 7.61233 11.23453 -5503.2677 -2652.7292)" d="M304.0969 438.09757a27.980104 22.839182 0 01-3.6573 4.98988" stroke-width=".244683"/>
        <ellipse transform="matrix(.62322 0 0 1.1695 374.10557 175.77792)" cx="390.90909" cy="168.18182" rx="138.18182" ry="73.63636" stroke-width="3.514" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M613.78712 354.34969c-2.98481-6.59539-6.23113-12.10397-9.6112-15.40045-1.06373-1.01304-2.35819-1.67291-3.61696-2.41517-1.42826-.84061-2.85931-1.67654-4.28327-2.52444-1.69395-1.02113-3.40186-2.04072-4.91796-3.31998-1.39554-1.30279-2.50589-2.82209-3.12408-4.65014-.36335-1.42638-.48042-2.9666.60563-4.11942 1.35665-.81313 2.87365-.4826 4.23126.178 1.71397.76043 3.30635 1.73475 4.87651 2.75752 1.54688 1.02579 3.04851 2.11771 4.24828 3.55335 1.35095 1.60912 2.26644 3.1287 2.52862 5.2672.14243 1.74939.0105 3.48319-.14745 5.22768l-.594-.0107.00054-.00042c.17256-1.74053.3016-3.47208.15893-5.21857-.25981-2.13127-1.18054-3.63128-2.527-5.23126-1.20083-1.42937-2.70306-2.51485-4.24768-3.53664-1.56938-1.02297-3.16201-1.99258-4.87764-2.74747-1.40248-.59958-1.77834-.90775-3.06342-.17143-1.09218 1.11553-.96733 2.64486-.60712 4.04341.61668 1.81943 1.72687 3.32532 3.11982 4.61729 1.51826 1.27258 3.22449 2.28984 4.91748 3.3079 1.42538.84642 2.8566 1.68301 4.28536 2.52373 1.26457.74172 2.55915 1.40438 3.61908 2.42867 2.9904 3.78463 5.80887 9.14671 8.53735 15.28916" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M626.97861 356.26803c6.2258-7.0041 5.69434-15.66107 6.37618-25.75847.009-.88955-.31978-1.74158-.78787-2.4865.12969-.26623-2.52142-1.12841-2.262-.63629-7.89206-.39959-9.42286 16.9182-9.20578 27.36911m.45978-.55579c.58528-9.92613-.23067-23.87538 8.16247-26.90145 1.18741-.10013 2.5155-.21456 3.43676.6978.46666.75719.79873 1.61678.78792 2.51668.0625 13.37682-.2267 17.8729-6.73357 25.18437" stroke-width="3" stroke-linejoin="round"/>
        <path d="M624.8523 409.59039c1.53468-2.6271 3.10517-5.22626 4.80729-7.74846 1.2911-1.70107 2.2613-3.66571 3.79282-5.17046 2.59222-2.60082 5.6567-4.58613 8.17779-6.85546 6.99651-6.33777 10.58861-14.20773 8.42079-23.29171-.20985-1.01728-.93175-1.67001-1.71648-2.27851-9.59838-4.55385-12.34089 14.09613-14.27272 19.94748-.58776 1.77879-1.18344 3.55578-1.66156 5.36752-.80155 2.1641-.72079 4.31371-.59971 6.38559.14236.82461.20128 1.65982.36998 2.48029l-.59118.006v0c-.16651-.8217-.22688-1.65764-.37032-2.48293-.31808-2.23445.0374-4.43413.60624-6.40994.48141-1.8122 1.07741-3.59047 1.66433-5.3709 2.1839-6.75994 5.23874-25.26839 15.44317-19.96975.79147.62072 1.51029 1.29026 1.71932 2.3199 2.58869 9.2249-1.95338 17.21122-8.42879 23.3523-2.73806 2.43361-5.79646 4.42366-8.17766 6.85347-1.54279 1.49315-2.51158 3.46155-3.80831 5.15743-1.70376 2.51625-3.27344 5.11087-4.7858 7.74649l-.5896-.038zM626.06728 443.11621c1.2623-1.96378 2.31684-4.03772 3.33386-6.13538.70242-1.42733.9526-2.94968 1.12619-4.50649 1.46271-7.20206 9.74908-33.91953 20.16867-28.9544 6.70942 3.622 1.15814 11.01419-2.91213 14.41434-4.3232 4.75841-9.74109 8.38396-13.93393 13.12178-.98771.97636-2.00666 1.90976-2.90886 2.96905l-.58561-.0477v0c.90078-1.06129 1.91754-1.99743 2.90781-2.97199 4.61977-4.35792 9.31425-8.83647 13.94237-13.1282 4.15253-3.54487 9.55369-10.70087 2.90436-14.29754-8.27145-3.58421-18.78723 22.87639-18.99386 28.90794-.17429 1.56431-.42939 3.09305-1.1372 4.52596-1.01945 2.09567-2.0724 4.16943-3.32014 6.14111l-.5909-.0381z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M604.84177 365.74621l-.68879-5.56169 4.26479-3.08632 6.06134-2.27234 4.82155.92665 5.08784-1.67822 4.32487 2.90137 2.1911 5.26452-2.89696 4.91162-1.4427 2.22255.81321 4.10132-4.35878 4.5916-3.61153 1.92291-7.60168-.48448-3.15261-1.07463-2.02233 1.17373-3.5207-2.00468-1.16513-2.88 1.05404-5.89076 1.70921-2.79628z"/>
        <g stroke-width="2.47581" stroke-linecap="round" stroke-linejoin="round">
          <path d="M603.702732 367.494143c-3.84575236 6.6458848-.90957894 14.440631 6.71017077 11.2588074.793488-.5095516 1.53831737-.41586415 2.37985903-.1155047 7.1757625 4.63162985 15.24605868-2.42376569 14.03601444-9.67760985.05872085-1.04569547 1.07087006-1.7563793 1.73987064-2.45937423 6.25071475-4.75945656-2.5338067-15.96664698-8.98731817-10.58755096-.36146423.52890762-.92112026.49767307-1.47069385.31001758-.82381831-.26124177-1.61730588-.61597089-2.46108653-.81184256-5.63312867-1.05994261-11.87072991 2.6411618-11.43446963 8.2892433.04601127.57943161.2472029 1.11570287.4713844 1.64507626l-.26621511.46145563h0c-.2283373-.53140231-.43675998-1.06927272-.48523014-1.65211722-.63715727-6.4589067 6.33984428-10.2108584 11.93623904-9.22781058.84500225.1983226 1.64093155.55060684 2.46664013.81204613.54592215.1811865 1.05965993.18612852 1.40822288-.33416913 7.97078002-5.11252276 14.3115731 5.77482212 8.65219692 11.59924997-.35078415.36883233-.72352504.72016533-1.07190914 1.08994753-.09405747.09988497-.14909853.23963828-.26224539.31718295-.05296894.03631536.03642723-.2382936.06256705-.18027412.03698567.08266679-.01409877.18008776-.02185375.27060242 1.14860526 8.571813-7.1889838 14.46068663-14.54028183 10.61918704-.83469379-.29608557-1.55692914-.37154919-2.34184576.13013077-7.43950234 5.6944084-10.80721797-9.48967638-6.32917591-12.2555126l-.1917497.49942068z" stroke-width="2.9999884932"/>
          <path d="M614.07044375 365.5661196c-.56171356.25648687-1.70655057 1.99291808-1.83376432 2.706176-.0088356.04960487.12316402-.09516345.14307162-.0489339.05758872.13467507.01644833.29235126.02471926.43848789.1922922.9331362.66736819 1.73104415 1.44939453 2.27515401.90336118.488154 1.8081614.21395 2.68964773-.16616282.66347522-.28559725 1.20716979-.6661005 1.65107322-1.23276172.01498129-.01893322-.04868905.07719438-.0471869.05348934.0092519-.14619782.04744581-.28899705.07127583-.43355352.01660801-.48550392-.16111758-.9126176-.33955743-1.35158623l.26651068-.46058741h0c.18430872.44266172.3688768.87512142.36444058 1.3658439-.17540932 1.30745961-1.00851424 2.0512454-2.1570028 2.5557468-.90093035.38122695-1.82180835.64207163-2.7396202.14598387-.7947637-.5512432-1.28104584-1.3546727-1.49050941-2.29910992-.10719623-1.31389106.82244107-3.43467007 1.98925323-3.8747581 1.25149934-.67498713 3.32493727.5052861 3.76504073 1.63392936l-.2053615.49488512c.02215525-2.00476859-2.6137986-2.25403998-3.60142485-1.80224268z" stroke-width="2.9999884932"/>
        </g>
      </g>
    </svg>
    <navbar></navbar>
    <router-view style="z-index: 1;"></router-view>
  </div>
</template>

<script>
import "normalize.css/normalize.css"
import Navbar from "./components/Navbar"

export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
:root {
    --main-color: #b0c2bc;
    --main-color-light: #f1f4f3;
    --main-color-dark: #4e655d;
}

html {
  background-color: var(--main-color-light);
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-color-light);
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
