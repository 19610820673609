<template>
    <div class="invoices" v-if="invoices.length">
        <div class="invoices-list">
            <div class="invoice" v-for="invoice in data" v-bind:key="invoice.id" v-on:click="selected = invoice" v-bind:class="{ 'selected' : selected && selected == invoice}">
                <span>{{invoice.date.toDate().toLocaleDateString("nl-be", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }}</span><span>€ {{ invoice.price.toFixed(2) }}</span>
            </div>
            <div class="invoice" v-if="this.moreData">
                <button v-on:click="load()">Meer ontvangsten inladen</button>
            </div>
        </div>
        <div class="invoices-selected" v-if="selected">
            <h1>{{selected.date.toDate().toLocaleDateString("nl-be", { year: 'numeric', month: 'long', day: 'numeric' }) }}</h1>
            <p v-if="selected.comment"><strong>Opmerking: </strong>{{selected.comment}}</p>
            <table>
                <tr v-for="flower in selected.flowers" :key="flower.name">
                    <td class="number">{{ flower.amount }}</td>
                    <td class="flower-name">{{ flower.name }}</td>
                    <td class="number flower-price">{{ flower.price.toFixed(2) }}</td>
                </tr>
            </table>
            <div class="invoice__summary">
                <span>Subtotaal:</span>
                <span class="invoice__price">{{ selected.price.toFixed(2) }}</span>
                <span v-if="selected.vatrate">BTW:</span>
                <span v-if="selected.vatrate" class="invoice__price">{{ (selected.price * selected.vatrate).toFixed(2) }}</span>
                <span v-if="selected.vatrate">Totaal:</span>
                <span v-if="selected.vatrate" class="invoice__price">{{ (selected.price * (1 + selected.vatrate)).toFixed(2)}}</span>
            </div>
        </div>
    </div>
    <div class="invoices" v-else>
        <span>Nog geen ontvangsten beschikbaar.</span>
    </div>
</template>

<script>
import firebase from "firebase/app";

export default {
    data() {
        return {
            data: [],
            selected: null, 

            // properties used for pagination
            pageSize: 20,
            lastDoc: null,
            moreData: true,
        }
    },
    methods: {
        load: function() {
            let cursor = this.$firestore.invoices.orderBy('date', 'desc')
            if (this.lastDoc) {
                cursor = cursor.startAfter(this.lastDoc)
            }
            cursor.limit(this.pageSize).get().then(
                result => {
                    this.lastDoc = result.docs[result.docs.length - 1]
                    this.data.push(...result.docs.map(doc => doc.data()))
                    if (result.docs.length < this.pageSize) {
                        this.moreData = false
                    }
                }
            )
        }
    },
    firestore() {
      return {
        invoices: firebase.app().firestore().collection('invoices'),
      }
    },
    mounted() {
        this.load()
    }
}
</script>

<style scoped>
.invoices {
    width: 65%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    background-color: white;
    border: 1px solid var(--main-color-dark);
    border-radius: 4px;
    max-height: 100%;
    overflow-y: scroll;
}
.invoices > span {
    margin: 15px;
}
.invoices-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;
    overflow-y: scroll;
}
.invoice {
    padding: 15px;
    border-bottom: 1px solid var(--main-color-dark);
    display: flex;
    justify-content: space-between;
}
.invoice.selected {
    background-color: var(--main-color);
    color: white;
}
.invoice:last-of-type {
    border: none;
}
.invoices-selected {
    width: 40%;
    padding: 40px;
    border-left: 1px solid var(--main-color-dark);
}
.invoices-selected h1{
    margin-top: 0;
    text-align: center;
}
table {
    width: 100%;
    margin-top: 1em;
}
.flower-name {
    padding-left: 5px;
    font-weight: bold;
}
table .number {
    text-align: end;
}
.invoice__summary {
    border-top: 1px solid var(--main-color);
    padding-top: 1rem;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    font-size: 1.1rem;
}
.invoice__price {
    font-weight: bold;
    text-align: right;
    width: 100%;
}
.invoice__price::before {
    float: left;
    content: "€";
    padding-right: 0.5rem;
}
button {
    padding: 8px;
}
</style>