<template>
    <div>
        <router-link to="/bouquet" tag="button"><span>💐</span><br>Boeket</router-link>
        <router-link to="/drybouquet" tag="button"><span>🌾</span><br>Droogboeket</router-link>
        <router-link to="/deco" tag="button" disabled><span>🏺</span><br>Decoratie</router-link>
        <router-link to="/stock" tag="button"><span>📝</span><br>Inkoop Verse bloemen</router-link>
        <router-link to="/drystock" tag="button"><span>📝</span><br>Inkoop Droogbloemen</router-link>
        <router-link to="/invoices" tag="button"><span>💶</span><br>Ontvangsten</router-link>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: fit-content;
    margin: 0 auto;
}
button, router-link {
    width: 150px;
    height: 150px;
    margin: 1rem;
}
button span, router-link span {
    font-size: 5rem;
}
button:disabled span {
    opacity: 0.3;
    filter: grayscale(100%);
}
</style>